<template>
  <layout>
    <template slot="headerMenu">
      <div class="header-menu">
        <div class="header-menu-wraper">
          <div class="header-menu-title">搜索结果</div>
        </div>
      </div>
    </template>
  </layout>
</template>
<script>
export default {
  name: "index",
  data() {
    return {
      currentMenu: ["Help"],
    };
  },
  created() {
    this.currentMenu = [this.$route.name];
  },
};
</script>
<style>
.main {
}
.item-list {
  background-color: #eeee;
}
.main .ant-tabs-tab-active {
  background-color: #fff !important;
}
.main .tips {
  padding: 10px 20px;
  font-size: 12px;
}
.list-content {
  padding: 20px;
  box-sizing: border-box;
}
.card-list-info {
  background-color: #fff;
  border-radius: 8px;
  padding: 10px 10px 20px 10px;
}
</style>